<template>
  <div>
    <div
      v-for="(r , i) in rows.filter(a => a.requestStatus != 2)"
      :key="i"
      class="row mb-2 border row align-items-center "
    >
      <div class="col-lg-2 col-md-2 d-grid text-center">
        <center>
          <b-img
            :src="r.firmInfo.firmLogo === '' ? require('@/assets/images/firmnoImage.png') : r.firmInfo.firmLogo"
            class="img-fluid"
            width="100"
            heigt="100"
          />
          <br>
          <br>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            class="mr-1 mb-1"
            variant="relief-primary"
            @click="onGetFirmClicked(r)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-30"
            />
            <span>Firma Envanter</span>
          </b-button>
          <br>
          <b-badge
            :variant="statusVariant(r.requestStatus)"
            class="badge-glow mr-1"
          >
            Talep Durumu : {{ durum(r.requestStatus) }}
          </b-badge>
        </center>
      </div>
      <div class="col-lg-4 col-md-4 row align-items-center mt-1">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <th class="text-danger">
                Firma Unvanı:
              </th>
              <td class="text-center">
                {{ r.firmInfo.firmTitle }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Pozisyon İsmi
              </th>
              <td class="text-center">
                {{ r.position }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Personel Sınıfı:
              </th>
              <td class="text-center">
                {{ r.staffClass }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Personel Faktör:
              </th>
              <td class="text-center">
                {{ r.factorPosition.staffPositionName }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Alınacak Kişi:
              </th>
              <td class="text-center">
                {{ r.personelCount }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Çalışma Şekli:
              </th>
              <td class="text-center">
                {{ r.jobType }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-4 col-md-4 row align-items-center mt-1">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <th class="text-danger">
                Talep Giriş Tarihi:
              </th>
              <td class="text-center">
                {{ strToDate(r.requestOpenDate) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Görev Açıklaması:
              </th>
              <td class="text-center">
                {{ r.shortPositionDescription }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Eksik Kayıtlar
              </th>
              <td class="text-center">
                <b-col>
                  <b-row
                    v-for="(ey,y) in cevir(r)"
                    :key="y"
                    class="pl-1"
                  >
                    {{ ey }}
                  </b-row>
                </b-col>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-2 col-md-12 inline-blocks text-center">
        <center>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            class="mr-1 mb-1"
            variant="relief-success"
            @click="onGetStaffRequestClicked(r)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-25"
            />
            <span>Talep Düzenle</span>
          </b-button>
          <br>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            class="mr-1 mb-1"
            variant="relief-danger"
            @click="onCloseStaffRequestClicked(r)"
          >
            <feather-icon
              icon="XCircleIcon"
              class="mr-25"
            />
            <span>Talebi Kapat</span>
          </b-button>
          <br>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            class="mr-1 mb-1"
            variant="relief-secondary"
            :disabled="r.requestStatus === -1"
            @click="onPassivStaffRequestClicked(r)"
          >
            <feather-icon
              icon="XCircleIcon"
              class="mr-25"
            />
            <span>Talebi Pasif Et</span>
          </b-button>
        </center>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BButton, VBModal, BCol, BRow, BBadge, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import * as apiRequest from '@/api/islemler/firmRequest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton, BCol, BRow, BBadge, BImg,

  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      rows: [],
      req: {},
      // resim: require('@/assets/images/firmnoImage'),
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        0: 'light-success',
        '-1': 'light-secondary',
      }
      return status => statusColor[status]
    },
  },
  created() {
    this.getSummary()
  },
  mounted() {

  },
  methods: {
    getSummary() {
      apiRequest.StaffRequestSummaryIncompleted().then(res => {
        this.rows = res.data.list
      })
    },
    clearModal() {
    },
    onDataGridItemClicked(params) {
      return params
    },
    onGetStaffRequestClicked(row) {
      this.$router.push({ name: 'yeni-talep', params: { firmStaffRequestId: row.staffRequestId, firmInfo: row.firmInfo } })
    },
    onCloseStaffRequestClicked(row) {
      const h = this.$createElement
      const messageVNode = h('div', [
        h('p', [`${row.firmInfo.firmTitle} firmasının ${row.position} talebi için bir daha işlem yapamayacaksınız!!`]),
        h('p', { class: ['text-center font-weight-bold text-danger'] }, ['Emin misiniz?']),
      ])
      this.$bvModal
        .msgBoxConfirm(messageVNode, {
          title: 'Talep Kapatma Onayı',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Evet',
          cancelTitle: 'Hayır',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            apiRequest.GetStaffRequestById(row.staffRequestId).then(res => {
              this.req = res.data.entity
              this.req.requestStatus = 2
              apiRequest.UpdateStaffRequest(this.req).then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Talep Durum',
                    text: `${row.firmInfo.firmTitle} firmasının ${row.position} talebi kapatılmıştır..`,
                    icon: 'PlayIcon',
                    variant: 'success',
                  },
                })
                this.$router.go(0)
              })
            })
          }
        })
    },
    onPassivStaffRequestClicked(row) {
      let talep = null
      apiRequest.GetStaffRequestById(row.staffRequestId).then(res => {
        talep = res.data.entity
        talep.requestStatus = -1
        apiRequest.UpdateStaffRequest(talep).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Talep Durum',
              text: `${row.firmInfo.firmTitle} firmasının ${row.position} talebi pasife alınmıştır..`,
              icon: 'PlayIcon',
              variant: 'success',
            },
          })
          this.$router.go(0)
        })
      })
    },
    onGetFirmClicked(row) {
      this.$router.push({ name: 'firma-kart', params: { firmId: row.firmInfoId } })
    },
    durum(secim) {
      switch (secim) {
        case 0:
          return 'Aktif'
        case 1:
          return 'Eşleştirilmiş'
        case 2:
          return 'Kapatılmış'
        case -1:
          return 'Pasif'
        default:
          return 'Aktif'
      }
    },
    strToDate(st) {
      let yil = ''
      yil = st.substring(0, 4)
      let ay = ''
      ay = st.substring(5, 7)
      let gun = ''
      gun = st.substring(8, 10)
      return `${gun}.${ay}.${yil}`
    },
    cevir(row) {
      let problems = []
      const retvals = []
      let say = ''
      if (row.validationErrors === null) return ''
      problems = row.validationErrors.split(',')
      problems.forEach((value, i) => {
        say = `${i.toString()} adet eksik giriş`
        switch (value) {
          case 'StaffClass': retvals.push('Personel Sınıfı')
            break
          case 'Position': retvals.push('Pozisyon')
            break
          case 'FactorPositionId': retvals.push('Pozisyon Gereklilik Seviyesi')
            break
          case 'ShortPositionDescription': retvals.push('Kısa Görev Tanımı')
            break
          case 'PositionDescription': retvals.push('Görev Açıklaması')
            break
          case 'JobType': retvals.push('Çalışma Şekli')
            break
          case 'PersonelCount': retvals.push('Alınacak kişi sayısı')
            break
          case 'Gender': retvals.push('Cinsiyet')
            break
          case 'GenderImportanceLevelId': retvals.push('Cinsiyet Gereklilik Seviyesi')
            break
          case 'MaritalStatus': retvals.push('Medeni Hali')
            break
          case 'MaritalImportanceLevelId': retvals.push('Medeni Hal Gereklilik Seviyesi')
            break
          case 'AgeMax': retvals.push('Yaş Maksimum')
            break
          case 'AgeMin': retvals.push('Yaş Minimum')
            break
          case 'AgeImportanceLevelId': retvals.push('Yaş Gereklilik Seviyesi')
            break
          case 'WeightMax': retvals.push('Kilo Maksimum')
            break
          case 'WeightMin': retvals.push('Kilo Minimum')
            break
          case 'WeightImportanceLevelId': retvals.push('Kilo Gereklilik Seviyesi')
            break
          case 'HeightMax': retvals.push('Boy Makksimum')
            break
          case 'HeightMin': retvals.push('Boy Minimum')
            break
          case 'HeightImportanceLevelId': retvals.push('Boy Gereklilik Seviyesi')
            break
          case 'MilitaryStatus': retvals.push('Askerlik Durumu')
            break
          case 'MilitaryStatusImportanceLevelId': retvals.push('Askerlik Gereklilik Seviyesi')
            break
          case 'Towns': retvals.push('İlçeler')
            break
          case 'TownsImportanceLevelId': retvals.push('İlçe Gereklilik Seviyesi')
            break
          case 'IsNotDisabledImportanceLevelId': retvals.push('Engellik Durumu Olmamalı')
            break
          case 'IsNotSmokerImportanceLevelId': retvals.push('Sigara Kullanmamalı')
            break
          case 'CanTravelImportanceLevelId': retvals.push('Seyahat Engeli Olmamalı')
            break
          case 'HasWorkShiftsImportanceLevelId': retvals.push('Vardiyalı Çalışabilmeli')
            break
          case 'HasReferencesImportanceLevelId': retvals.push('Referans Gösterebilmeli')
            break
          case 'HasNotCriminalRecordImportanceLevelId': retvals.push('Sabıka Kaydı Olmamalı')
            break
          case 'CanDriveImportanceLevelId': retvals.push('Aktif Araç Kullanabilmeli')
            break
          case 'DriverLicenseTypeId': retvals.push('Ehliyet Sınıfı')
            break
          case 'DriverLicenseImportanceLevelId': retvals.push('Ehliyet Sınıfı Gereklilik Seviyesi')
            break
          default:
            // retvals.push(value)
            break
        }
      })
      if (retvals.length === 0) {
        say = 'Eksik yoktur'
      } else {
        say = `${retvals.length} adet kayıt eksik`
      }
      retvals.push(say)
      return retvals // .join('\r\n ')
    },
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
